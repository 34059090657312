import axios from 'axios'

const API = 'landing/estadistica'

const Estadistica = {
    getVisitasUltimos12Meses(params){
        return axios.get(`${API}/visitas-ultimos-doce-meses`,{params})
    },
    getGraficasVisitas(params){
        return axios.get(`${API}/visitas-graficas`,{params})
    },
    getDatosVisitas(params){
        return axios.get(`${API}/visitas-datos-generales`,{params})
    },
    getDatos(params){
        return axios.get(`${API}/detalle-datos-generales`,{params})
    },
    getImagenesClicks(params){
        return axios.get(`${API}/visitas-imagenes`,{params})
    },
    getVisitantes(params){
        return axios.get(`${API}/visitantes`,{params})
    },
}

export default Estadistica
