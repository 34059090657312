<template>
    <section class="custom-scroll overflow-auto" style="height:calc(100vh - 205px);">
        <div class="box-graphic p-4 mb-4">
            <p class="text-general f-18 f-600 mb-4">Visitas en los últimos 12 meses</p>
            <div class="d-flex">
                <div class="d-flex flex-column">
                    <div class="box-counter py-2">
                        <p class="text-center text-general f-14">
                            Mes de mayor <br /> ingreso
                        </p>
                        <p class="text-center text-general f-14 f-600 my-2">
                            {{ mayorIngreso.mes.nombre }}
                        </p>
                        <p class="f-14 text-center text-general">
                            {{ mayorIngreso.mes.vistas }}
                        </p>
                    </div>
                    <div class="box-counter py-2 mt-3">
                        <p class="text-center text-general f-14">
                            Mes de menor <br /> ingreso
                        </p>
                        <p class="text-center text-general f-14 f-600 my-2">
                            {{ menorIngreso.mes.nombre }}
                        </p>
                        <p class="f-14 text-center text-general">
                            {{ menorIngreso.mes.vistas }}
                        </p>
                    </div>
                </div>
                <div class="col">
                    <echart :options="visitasUltimos12Meses" />
                </div>
            </div>
        </div>
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center mb-4">
            <div class="col-auto f-17 text-general">
                Rango de análisis
            </div>
            <div class="col-2">
                <el-select v-model="rango" class="w-100" size="small">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
            <div class="col-3 pl-1">
                <el-date-picker
                v-model="fecha"
                class="w-100"
                size="small"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                :disabled="disable_fecha"
                popper-class="date-picker-edit-popper"
                type="daterange"
                unlink-panels
                start-placeholder="Fecha inicio"
                end-placeholder="Fecha fin"
                />
            </div>
            <div class="btn-general px-3" @click="getGraficasVisitas">
                Mostrar informe
            </div>
        </div>
        <div class="box-graphic p-4 mb-4">
            <p class="text-general f-18 f-600 mb-4">Vistas promedio semanal</p>
            <div class="d-flex">
                <div class="d-flex flex-column">
                    <div class="box-counter py-2">
                        <p class="text-center text-general f-14">
                            Día de mayor <br /> ingreso
                        </p>
                        <p class="text-center text-general f-14 f-600 my-2">
                            {{ mayorIngreso.semana.nombre }}
                        </p>
                        <p class="f-14 text-center text-general">
                            {{ mayorIngreso.semana.vistas }}
                        </p>
                    </div>
                    <div class="box-counter py-2 mt-3">
                        <p class="text-center text-general f-14">
                            Día de menor <br /> ingreso
                        </p>
                        <p class="text-center text-general f-14 f-600 my-2">
                            {{ menorIngreso.semana.nombre }}
                        </p>
                        <p class="f-14 text-center text-general">
                            {{ menorIngreso.semana.vistas }}
                        </p>
                    </div>
                </div>
                <div class="col">
                    <echart :options="vistasPromedioSemanal" />
                </div>
            </div>
        </div>
        <div class="box-graphic p-4 mb-4">
            <p class="text-general f-18 f-600 mb-4">Visitas promedio por hora</p>
            <div class="d-flex">
                <div class="d-flex flex-column">
                    <div class="box-counter py-2">
                        <p class="text-center text-general f-14">
                            Hora de mayor <br /> venta promedio
                        </p>
                        <p class="text-center text-general f-14 f-600 my-2">
                            {{ mayorIngreso.horas.nombre }}
                        </p>
                        <p class="f-14 text-center text-general">
                            {{ mayorIngreso.horas.vistas }}
                        </p>
                    </div>
                    <div class="box-counter py-2 mt-3">
                        <p class="text-center text-general f-14">
                            Hora de menor <br /> venta promedio
                        </p>
                        <p class="text-center text-general f-14 f-600 my-2">
                            {{ menorIngreso.horas.nombre }}
                        </p>
                        <p class="f-14 text-center text-general">
                            {{ menorIngreso.horas.vistas }}
                        </p>
                    </div>
                </div>
                <div class="col">
                    <echart :options="visitasPromedioPorHora" />
                </div>
            </div>
        </div>
        <div class="box-graphic p-4 mb-4">
            <p class="text-general f-18 f-600 mb-3">{{ $config.vendedor }} que ha generado más visitas</p>
            <div class="row mx-0">
                <div class="col">
                    <div class="row mx-0">
                        <div v-for="(l,k) in datosGenerales.leecheros" :key="k" class="card-leechero mx-3">
                            <img :src="l.foto" class="obj-cover br-6" width="100%" height="115" />
                            <p class="text-general text-center f-14 nombre-leechero mt-2">
                                {{ l.nombre }}
                            </p>
                            <div class="row mx-0 align-items-center">
                                <i class="icon-eye f-14 text-general" />
                                <div class="col f-14 text-grey">
                                    {{ l.visitas }}
                                </div>
                            </div>
                            <div class="row mx-0 align-items-center">
                                <i class="icon-cliente f-18 text-general" />
                                <div class="col f-14 text-grey">
                                    {{ l.clientes }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto" @click="verMas('Vendedores que ha generado más visitas',1)">
                    <div class="box-ver-mas cr-pointer d-flex flex-column justify-content-center text-white text-center">
                        <p>Ver más</p>
                        <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
        </div>
        <div class="box-graphic p-4 mb-4">
            <p class="text-general f-18 f-600 mb-3">Cedis que han generado más visitas</p>
            <div class="row mx-0">
                <div class="col">
                    <div class="row mx-0">
                        <div v-for="(c,cedis) in datosGenerales.cedis" :key="cedis" class="card-cedis mx-3">
                            <img :src="c.foto" class="obj-cover br-6" width="100%" height="115" />
                            <p class="text-general f-14 nombre-cedis mt-2">
                                {{ c.nombre }}
                            </p>
                            <div class="row mx-0 align-items-center">
                                <i class="icon-eye f-14 text-general" />
                                <div class="col f-14 text-grey">
                                    {{ c.visitas }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto" @click="verMas('Cedis que han generado más visitas',2)">
                    <div class="box-ver-mas cr-pointer d-flex flex-column justify-content-center text-white text-center">
                        <p>Ver más</p>
                        <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
        </div>
        <div class="box-graphic p-4 mb-4">
            <p class="text-general f-18 f-600 mb-3">Categorías con más clics</p>
            <div class="row mx-0">
                <div class="col">
                    <div class="row mx-0">
                        <div v-for="(catg,c) in datosGenerales.categorias" :key="c" class="mr-3">
                            <div class="card-categoria p-2">
                                <p class="text-general f-14 nombre-categoria mt-2">
                                    {{ catg.nombre }}
                                </p>
                            </div>
                            <p class="text-grey f-14 pl-3 mt-1">
                                <i class="icon-cursor f-12 text-general" />
                                {{ catg.clicks }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-auto" @click="verMas('Categorías con más clics',3)">
                    <div class="box-ver-mas-cat cr-pointer d-flex flex-column justify-content-center text-white text-center">
                        <p>Ver más</p>
                        <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
        </div>
        <div class="box-graphic p-4 mb-4">
            <p class="text-general f-18 f-600 mb-3">Productos con más clics</p>
            <div class="row mx-0">
                <div class="col">
                    <div class="row mx-0">
                        <div v-for="(p,key) in datosGenerales.productos" :key="key" class="card-clics mx-3">
                            <img :src="p.foto" class="obj-cover br-6" width="100%" height="115" />
                            <p class="text-general f-14 nombre-clics mt-2">
                                {{ p.nombre }}
                            </p>
                            <p class="text-grey f-14">
                                {{ p.presentacion }}
                            </p>
                            <p class="text-grey f-14 mt-1">
                                <i class="icon-cursor" />
                                {{ p.clicks }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="box-ver-mas cr-pointer d-flex flex-column justify-content-center text-white text-center" @click="verMas('Productos con más clics',4)">
                        <p>Ver más</p>
                        <i class="icon-angle-right" />
                    </div>
                </div>
            </div>
        </div>
        <div class="box-graphic p-4 mb-4">
            <p class="text-general f-18 f-600 mb-3">Imágenes con más clics</p>
            <div class="row mx-0">
                <div v-for="(img,d) in imagenes" :key="d" class="mr-3 mb-3">
                    <img :src="img.imagen" class="obj-cover br-8" width="115" height="115" />
                    <p class="text-grey f-14 mt-1">
                        <i class="icon-cursor f-14" />
                        {{ img.clicks }}
                    </p>
                </div>
            </div>
        </div>
        <div class="box-graphic p-4 mb-4">
            <p class="text-general f-18 f-600 mb-3">Visitantes por genero</p>
            <div class="row mx-0">
                <div class="col">
                    <div class="d-flex br-5" style="height:30px;background-color:#EBEBEB;">
                        <template>
                            <el-tooltip placement="top" :content="`Mujer: ${agregarSeparadoresNumero(genero.otro)}%`" effect="light">
                                <div class="h-100 bg-general3 br-tl-5 br-bl-5" :style="`width:${genero.otro}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`Hombre: ${agregarSeparadoresNumero(genero.hombre)}%`" effect="light">
                                <div class="h-100 bg-general2" :style="`width:${genero.hombre}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`Otro: ${agregarSeparadoresNumero(genero.mujer)}%`" effect="light">
                                <div class="h-100 bg-gris br-tr-5 br-br-5" :style="`width:${genero.mujer}%;`" />
                            </el-tooltip>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row mx-3 my-4 bg-white br-b-12 pb-3 justify-content-center">
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/person (1).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600 mx-2">
                                Mujer {{ agregarSeparadoresNumero(genero.otro) }}%
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/person (2).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600 mx-2">
                                Hombre {{ agregarSeparadoresNumero(genero.hombre) }}%
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/astronaut (1).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600 mx-2">
                                Otro {{ agregarSeparadoresNumero(genero.mujer) }}%
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-3 py-3 bg-white px-3 mt-4 text-general f-600 f-18 br-t-12">
            Visitantes por edad
        </div>
        <div class="row mx-3 bg-white py-3">
            <div class="col">
                <div class="d-flex br-5" style="height:30px;background-color:#EBEBEB;">
                    <el-tooltip v-for="(data, i) in visitas.edad" :key="i" placement="top" :content="`${data.edad}: cantidad ${(data.total)}`" effect="light">
                        <div v-if="data.estado != 11" class="h-100 br-tl-5 br-bl-5 br-tr-5 br-br-5" :style="`width:${data.porcentaje}%; background-color:${backgroundEdad(data.estado)}`" />
                        <div v-else class="h-100 br-tl-5 br-bl-5 br-tr-5 br-br-5 bg-dark" :style="`width:${data.porcentaje}%;`" />
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="row mx-3 br-b-12 bg-white px-5 p b-3 justify-center">
            <div v-for="(data, i) in visitas.edad" :key="i" class="col-2 px-2 my-2 text-muted2" style="min-height:50px;">
                <div class="w-100 h-100 border br-12 px-3">
                    <p class="f-17 tres-puntos py-1">
                        <span class="f-600" :style="`color:${backgroundEdad(data.estado)};`"> {{ data.edad }}:   {{ data.porcentaje }}% </span>
                    </p>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-ver-mas ref="modalVerMas" :titulo="tituloModal" />
    </section>
</template>

<script>
import Estadistica from '~/services/landing/landingEstadisticas'
import moment from 'moment'
export default {
    components: {
        modalVerMas: () => import('./partials/modalVerMas')
    },
    data(){
        return {
            cargando: false,
            tituloModal: '',
            options: [{
                value: 1,
                label: 'Hoy'
            }, {
                value: 2,
                label: 'Ultima semana'
            }, {
                value: 3,
                label: 'Ultimo mes'
            }, {
                value: 4,
                label: 'Ultimo 3 meses'
            }, {
                value: 5,
                label: 'Personalizado'
            }],
            rango: 3,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            visitasUltimos12Meses: {
                tooltip:{
                    trigger: 'axis',
                    formatter: params => {
                        const data = params[0]
                        return `${data.name} - ${this.agregarSeparadoresNumero(data.value)} visitas`
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: '#DFE4E8'
                        }
                    },
                    axisLabel: {
                        color: '#637381'
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: '#DFE4E8'
                        }
                    },
                    axisLabel: {
                        color: '#637381'
                    },
                    axisTick: {
                        show: false
                    }
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        barWidth: 50,
                        itemStyle: {
                            color: "#DFE4E8"
                        },
                    },
                ]
            },
            vistasPromedioSemanal: {
                tooltip:{
                    trigger: 'axis',
                    formatter: params => {
                        const data = params[0]
                        return `${data.name}: ${this.agregarSeparadoresNumero(data.value)} visitas`
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: '#DFE4E8'
                        }
                    },
                    axisLabel: {
                        color: '#637381'
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: '#DFE4E8'
                        }
                    },
                    axisLabel: {
                        color: '#637381'
                    },
                    axisTick: {
                        show: false
                    }
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        barWidth: 80,
                        itemStyle: {
                            color: "#DFE4E8"
                        },
                    }
                ]
            },
            visitasPromedioPorHora: {
                tooltip:{
                    trigger: 'axis',
                    formatter: params => {
                        const data = params[0]
                        return `${data.name} - ${this.agregarSeparadoresNumero(data.value)} visitas`
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: '#DFE4E8'
                        }
                    },
                    axisLabel: {
                        color: '#637381'
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        lineStyle: {
                            color: '#DFE4E8'
                        }
                    },
                    axisLabel: {
                        color: '#637381'
                    },
                    axisTick: {
                        show: false
                    }
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        barWidth: 25,
                        itemStyle: {
                            color: "#DFE4E8"
                        },
                    }
                ]
            },
            visitasDoceMeses: [],
            mayorIngreso:{
                mes: {
                    nombre: '',
                    vistas: 0
                },
                semana: {
                    nombre: '',
                    vistas: 0
                },
                horas: {
                    nombre: '',
                    vistas:0
                }
            },
            menorIngreso:{
                mes: {
                    nombre: '',
                    vistas: 0
                },
                semana: {
                    nombre: '',
                    vistas: 0
                },
                horas: {
                    nombre: '',
                    vistas:0
                }
            },
            graficasVistasPromedio: [],
            datosGenerales: [],
            imagenes: [],
            visitas: [],
            genero: {
                otro: 0,
                hombre: 0,
                mujer: 0,
            }
        }
    },
    computed: {
        idLanding(){
            // return 8
            return this.$route.params.idLanding
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(3, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;
            default:
                break;
            }
        },
    },
    created(){
        this.cargando = true;
    },
    mounted(){
        this.getVisitasUltimos12Meses()
        this.getGraficasVisitas()
    },
    methods: {
        verMas(tituloModal,tipo){
            let infoModal = {
                tipo: tipo,
                idLanding: this.idLanding,
                fechaInicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fechaFin: moment(this.fecha[1]).format('Y-MM-DD'),
            }

            this.tituloModal = tituloModal;
            this.$refs.modalVerMas.toggle(infoModal);
        },
        async getVisitasUltimos12Meses(){
            try {
                const { data } = await Estadistica.getVisitasUltimos12Meses({idLanding: this.idLanding});
                this.visitasDoceMeses = data?.data
                this.graficaDoceMeses()
            } catch (e){
                this.error_catch(e)
            }
        },
        graficaDoceMeses(){
            // -------  Grafica visitas ultimos 12 meses ----------
            let mes = [];
            let vistas = [];
            let data = this.visitasDoceMeses;

            // buscamos el mes con las visitas maximas y minimas
            let max = Math.max(...data.map(d=>d.vistas))
            let min = Math.min(...data.map(d=>d.vistas))

            for(let i = 0; i < data.length; i++){
                mes.push(data[i].nombreMes);
                vistas.push(data[i].vistas);

                if (data[i].vistas === max){
                    this.mayorIngreso.mes.nombre = data[i].nombreMes
                    this.mayorIngreso.mes.vistas = data[i].vistas
                }
                if (data[i].vistas === min){
                    this.menorIngreso.mes.nombre = data[i].nombreMes
                    this.menorIngreso.mes.vistas = data[i].vistas
                }
            }

            // Asignamos la data para mostrar en la grafica
            this.visitasUltimos12Meses.xAxis.data = mes;
            this.visitasUltimos12Meses.series[0].data = vistas;
        },
        async getGraficasVisitas(){
            try {
                this.cargando = true;
                let params = {
                    fechaInicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fechaFin: moment(this.fecha[1]).format('Y-MM-DD'),
                    idLanding: this.idLanding
                }

                const { data } = await Estadistica.getGraficasVisitas(params);
                this.graficasVistasPromedio = data?.data
                this.graficasVisitasPromedio()
                this.getDatosVisitas()
                this.getImagenesClicks()
                this.getVisitantes()
                this.cargando = false;
            } catch (e){
                this.cargando = true;
                this.error_catch(e)
            }
        },
        graficasVisitasPromedio(){
            // -------  Grafica visitas promedio semanal ----------
            let dia = [];
            let vistas = [];
            let data = this.graficasVistasPromedio.graficaSemana;

            // buscamos el dia con las visitas maximas y minimas
            let max = Math.max(...data.map(d=>d.visitas))
            let min = Math.min(...data.map(d=>d.visitas))

            for(let i = 0; i < data.length; i++){
                dia.push(data[i].dia);
                vistas.push(data[i].visitas);

                if (data[i].visitas === max){
                    this.mayorIngreso.semana.nombre = data[i].dia
                    this.mayorIngreso.semana.vistas = data[i].visitas
                }
                if (data[i].visitas === min){
                    this.menorIngreso.semana.nombre = data[i].dia
                    this.menorIngreso.semana.vistas = data[i].visitas
                }
            }

            // Asignamos la data para mostrar en la grafica promedio
            this.vistasPromedioSemanal.xAxis.data = dia;
            this.vistasPromedioSemanal.series[0].data = vistas;


            // -------  Grafica visitas promedio hora ----------
            let hora = [];
            let visitas = [];
            let dato = this.graficasVistasPromedio.graficaHora;

            // buscamos el dia con las visitas maximas y minimas
            let maxi = Math.max(...dato.map(d=>d.visitas))
            let mini = Math.min(...dato.map(d=>d.visitas))

            for(let i = 0; i < dato.length; i++){
                hora.push(dato[i].hora);
                visitas.push(dato[i].visitas);

                if (dato[i].visitas === maxi){
                    this.mayorIngreso.horas.nombre = dato[i].hora
                    this.mayorIngreso.horas.vistas = dato[i].visitas
                }
                if (dato[i].visitas === mini){
                    this.menorIngreso.horas.nombre = dato[i].hora
                    this.menorIngreso.horas.vistas = dato[i].visitas
                }
            }

            // Asignamos la data para mostrar en la grafica
            this.visitasPromedioPorHora.xAxis.data = hora;
            this.visitasPromedioPorHora.series[0].data = visitas;
        },
        async getDatosVisitas(){
            try {
                let params = {
                    fechaInicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fechaFin: moment(this.fecha[1]).format('Y-MM-DD'),
                    idLanding: this.idLanding
                }

                const { data } = await Estadistica.getDatosVisitas(params);
                this.datosGenerales = data?.data
                this.cargando = false;
            } catch (e){
                this.error_catch(e)
            }
        },
        async getImagenesClicks(){
            try {
                const { data } = await Estadistica.getImagenesClicks({idLanding: this.idLanding});
                this.imagenes = data?.data
            } catch (e){
                this.error_catch(e)
            }
        },
        backgroundEdad(estado){
            switch(estado){
            case 1:
                return '#01B8AA'
            case 2:
                return '#374649'
            case 3:
                return '#FD625E'
            case 4:
                return '#F2C80F'
            case 5:
                return '#284367'
            case 6:
                return '#8AD4EB'
            case 7:
                return '#FE9666'
            case 8:
                return '#3599B8'
            case 9:
                return '#72D4CC'
            case 10:
                return '#A66999'
            case 11:
                return '#E1B0B0'
            }
        },
        async getVisitantes(){
            try {
                let params = {
                    fechaInicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fechaFin: moment(this.fecha[1]).format('Y-MM-DD'),
                    idLanding: this.idLanding
                }

                const { data } = await Estadistica.getVisitantes(params);
                this.visitas = data?.data

                this.genero.otro = data.data.genero[0].porcentaje
                this.genero.hombre = data.data.genero[1].porcentaje
                this.genero.mujer = data.data.genero[2].porcentaje
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.box-graphic{
    box-shadow: 0px 1px 4px #00000014;
    background-color: #FFFFFF;
    border-radius: 12px;
    .box-counter{
        width: 158px;
        height: 119px;
        background-color: #F6F9FB;
        border-radius: 12px;
        border-radius: 12px;
    }
}

.card-leechero{
    width: 115px;
    .nombre-leechero{
        height: 38px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
        line-height: 19px;
    }
}

.card-cedis{
    width: 115px;
    .nombre-cedis{
        height: 38px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
        line-height: 19px;
    }
}

.card-categoria{
    width: 158px;
    height: 59px;
    border-radius: 8px;
    background-color: #F6F9FB;
    .nombre-categoria{
        height: 38px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
        line-height: 19px;
    }

}

.card-clics{
    width: 158px;
    border-radius: 8px;
    .nombre-clics{
        height: 38px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
        line-height: 19px;
    }

}

.box-ver-mas{
    width: 115px;
    height: 115px;
    border-radius: 8px;
    background-color: #000000;
}

.box-ver-mas-cat{
    width: 115px;
    height: 59px;
    border-radius: 8px;
    background-color: #000000;
}
</style>
